import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/home';
import Footer from './components/footer';
import Team from './components/team';
import Vision from './components/vision';
import Message from './components/message';
import Contact from './components/contact';
import Navbar from './components/navbar';
import Gallery from './components/gallery';
import Login from './components/login';
import Privatecomponents from './components/privatecomponents';
import Addcontent from './components/addcontent';
import Addhelp from './components/addhelp';
import Addupdate from './components/addupdate';
import Update from './components/update';
import Updatenotice from './components/updatenotice';
import Updatehelp from './components/updatehelp';
import Updatephoto from './components/updatephoto';
import Noticeupdated from './components/noticeupdated';
import Workupdated from './components/workupdated';
import Photoupdated from './components/photoupdated';
import Changepassword from './components/changepassword';
import Donate from './components/donate';
import ScrollToTop from './components/ScrollToTop';
import Loginhome from './components/loginhome';
import Rural from './components/rural';
import Studentawareness from './components/studentawareness';
import Charity from './components/charity';
import Womenrights from './components/womenrights';
import Womensafety from './components/womensafety';
import FAQs from './components/FAQs';


function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/teams' element={<Team />}></Route>
        <Route path='/vision' element={<Vision />}></Route>
        <Route path='/message' element={<Message />}></Route>
        <Route path='/rural' element={<Rural />}></Route>
        <Route path='/women-empowerment' element={<Womensafety />}></Route>
        <Route path='/divyang' element={<Womenrights />}></Route>
        <Route path='/needy-people' element={<Charity />}></Route>
        <Route path='/students-awareness' element={<Studentawareness />}></Route>
        <Route path='/FAQs' element={<FAQs/>}></Route>
        <Route path='/gallery' element={<Gallery />}></Route>
        <Route path='/updates' element={<Update />}></Route>
        <Route path='/contact-us' element={<Contact />}></Route>
        <Route path='/donate' element={<Donate />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route element={<Privatecomponents />}>
          <Route path='/login-home' element={<Loginhome />}></Route>
          <Route path='/login-home/add-content' element={<Addcontent />}></Route>
          <Route path='/login-home/add-help' element={<Addhelp />}></Route>
          <Route path='/login-home/update' element={<Addupdate />}></Route>
          <Route path='/login-home/notice' element={<Updatenotice />}></Route>
          <Route path='/login-home/notice/:_id' element={<Noticeupdated />}></Route>
          <Route path='/login-home/worklist' element={<Updatehelp />}></Route>
          <Route path='/login-home/worklist/:_id' element={<Workupdated />}></Route>
          <Route path='/login-home/photo-content' element={<Updatephoto />}></Route>
          <Route path='/api/v1/content/get_Contents_Update/:_id' element={<Photoupdated />}></Route>
          <Route path='/login-home/change-password' element={<Changepassword />}></Route>
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
