import React, { useEffect, useState } from "react";
import './css/photoupdated.css';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "./url";
import SyncLoader from "react-spinners/SyncLoader";


const Photoupdated = () => {
    const [image, setimage] = useState('');
    const [details, setdetails] = useState('');
    const [loading,setLoading] =useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const getContents = () => {
        const _id = params._id;
        localStorage.setItem("Id",_id);
        console.log(details);
        axios({
            method:"GET",
            url:`${BaseURL}/api/v1/content/get_Contents_Update/${_id}`,
            withCredentials:true,
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            if (res.status===200 ) {
                setdetails(res.data.data.details)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
    useEffect(() => {
        getContents();
        // eslint-disable-next-line
    }, []);
    const submit = () => {
        setLoading(true);
        const _id = localStorage.getItem("Id")
        const formdata = new FormData();
        formdata.append("_id",_id);
        formdata.append("image",image);
        formdata.append("details",details)
        axios({
            url :`${BaseURL}/api/v1/content/update_Content`,
            method : "PUT",
            withCredentials :true,
            data:formdata,
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            setLoading(false)
            if (res.status === 200) {
                alert("Content Updated")
                navigate("/login-home")
            }
        }).catch((err)=>{
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "400") {
                alert("Required Fields")
            } else if (code === 401) {
                alert("Unauthorised Request")
            } else {
                alert("Server Error")
            }
        })
    }
    return (
        <div>
            <h1 className="text-center" style={{ marginTop: 110 }}>Content Details</h1>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-4 mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label mb-2">Photo</label>
                        <input type="file" className="form-control mb-2" id="inputGroupFile02" onChange={(e) => setimage(e.target.files[0])} />
                        <label htmlFor="exampleFormControlTextarea1" className="form-label mb-2">Description</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Describe the Event And all important details" rows="3" onChange={(e) => setdetails(e.target.value)} value={details}></textarea>
                        <div className="text-center mt-3 mb-3"><SyncLoader loading={loading} /></div>                       
                        <div className="d-grid gap-2 col-3 mx-auto">
                            <button className="btn btn-primary mt-3" type="button" onClick={submit}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Photoupdated;