import React from "react";

const FAQs = () => {
    return (
        <div style={{ marginTop: "10rem" }}>
            <div className="container">
                <h4 className="text-center mt-5 mb-5 text-danger fw-bolder">Frequently Asked Questions</h4>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 mb-5">
                        <div className="accordion accordion-flush" id="accordionFlushExample">

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button text-danger fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        IS SEWA SATH V VIKAS FOUNDATION A REGISTERED NGO?
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Yes, SEWA SATH VIKAS FOUNDATION is Registered under Indian Trust 1882, dated 8th November 2023, with Government of India
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button text-danger fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        HOW DOES MY DONATION HELP?
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                    Your generous Donation is more than just financial support; it is a Beacon of hope for children with Disabilities and their families. It helps us provide the education they deserve, the support they need, and the love they may not always feel from the world. Every contribution you make is a step towards a brighter future, where every child can dream big and every individual with disabilities is celebrated for who they are. Your kindness brings warmth and light into their lives, and for that, we are deeply grateful
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button text-danger fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        WHAT IS THE MINIMUM AND MAXIMUM I CAN DONATE?
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Any amount is welcome. Even the smallest amount carries biggest value for us. You may donate any amount of your convenience. Besides this, you may donate food, girls clothes, stationery, blankets, toys, utensils etc. in kind.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button text-danger fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        DOES THE DONATION CARRY TAX EXEMPTION?
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Yes. Your donation is tax exempted under 80G of Income Tax Act 1961</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button text-danger fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        WILL I GET A RECEIPT FOR MY DONATION?
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Yes. SEWA SATH VIKAS FOUNDATION will acknowledge your financial contribution with the 80G receipt in a week’s time from the time of donation.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button text-danger fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                        CAN I MAKE AN OFFLINE DONATION?
                                    </button>
                                </h2>
                                <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Yes, you may do NEFT to the following Account Number or send a cheque / DD drawn in favor of “SEWA SATH V VIKAS FOUNDATION”.<br/>
                                        Account Name : SEWA SATH V VIKAS FOUNDATION<br/>
                                        Account Number:  700801010050176<br/>
                                        IFSC:  UBIN0570087<br/>
                                        Bank Location: UNION BANK OF INDIA, VILLAGE HUSENPUR, CHOCHAKPUR MARG GHAZIPUR,233001
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQs