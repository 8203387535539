import React, { useState } from "react";
import './css/addupdate.css';
import { useNavigate } from "react-router-dom";
import Logout from "./logout";
import { BaseURL } from "./url";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";


const Addupdate = () => {
    const [update, setupdate] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const notice = async () => {
        setLoading(true)
        axios({
            url: `${BaseURL}/api/v1/update/add_Notice`,
            method: "POST",
            withCredentials: true,
            data: ({ update }),
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("user")}`
            }
        }).then((res) => {
            setLoading(false)
            if (res.status === 200) {
                alert("Notice Posted")
                navigate("/login-home")
            }
        }).catch((err) => {
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code==="400") {
                alert("Required Fields")
            } else if (code === "401") {
                alert("Unauthorised Request")
            } else {
                alert("Internal Server Error") 
            }
        })
    }
    return (
        <div>
            <Logout />
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 mb-3 noticeboxxx">
                        <h1 className="text-center pt-3">Notice Update</h1>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label mb-2">Notice</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Update News Here" rows="5" onChange={(e) => setupdate(e.target.value)} value={update}></textarea>
                        </div>
                        <div className="text-center mt-3 mb-3" > <SyncLoader loading={loading} /> </div>
                        <div className="d-flex justify-content-center pb-3">
                            <button className="btn btn-primary mt-3" type="button" onClick={notice}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Addupdate;