import React, { useEffect, useState } from "react";
import './css/updatephoto.css';
import { useNavigate } from "react-router-dom";
import Logout from "./logout";
import { BaseURL } from "./url";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";


const Updatephoto = () => {
    const [data, setdata] = useState([]);
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate();
    const getphoto =  () => {
        axios({
            url:`${BaseURL}/api/v1/content/get_Content`,
            method:"GET",
            withCredentials:true,
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            if (res.status===200) {
                setdata(res.data.data)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
    useEffect(() => {
        getphoto();
    }, []);
    const deleteContent=(_id)=>{
        setLoading(true)
        axios({
            url:`${BaseURL}/api/v1/content/remove_Content`,
            method:"DELETE",
            withCredentials:true,
            data:({_id}),
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            setLoading(false);
            if (res.status===200) {
                alert("Content Deleted")
                navigate("/login-home")
            }
        }).catch((err)=>{
            setLoading(false)
            console.log(err);
        })
    }

    return (
        <div>
            <Logout/>
            <h1 className="text-center">List of Photo Content</h1>
            <div className="container">
            <div className="text-center mt-3 mb-3" > <SyncLoader loading={loading} /> </div>
                <div className="row d-flex justify-content-center">
                    {
                        data.map((item) =>

                            <div className="col-md-4 d-flex justify-content-between maincontent flex-column ">
                                <img src={item.image} className="img-fluid" alt="..." />
                                <p className="text-center">{item.details}</p>
                                <div className="d-flex justify-content-between">
                                    <button className="btn btn-primary" type="button" onClick={(()=>navigate(`/api/v1/content/get_Contents_Update/${item._id}`))}>Update</button>
                                    <button className="btn btn-primary" type="button" onClick={(()=>deleteContent(item._id))}>Delete</button>
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>
        </div>
    );
}
export default Updatephoto;