import React, { useState } from "react";
import './css/logout.css';
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { BaseURL } from "./url";
import SyncLoader from "react-spinners/SyncLoader";

const Logout = () => {
    const navigate = useNavigate();
    const [loading,setLoading]=useState(false);
    const logout = () => {
        setLoading(true);
        axios({
            url: `${BaseURL}/api/v1/user/logout`,
            method: "POST",
            withCredentials: true,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("user")}`
            }
        }).then((res) => {
            if (res.status===200) {                
                localStorage.clear();
                Cookies.remove('user')
                setLoading(false);
                navigate('/login');
            }
        }).catch((err) => {
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === 401) {
                alert("Unauthorised Request")
            } else {
                alert("Server Error");
            }
        })
    }
    return (
        <div>
            <div className="mr-5 mb-4 menubar" style={{ marginTop: 110 }}>
                <button className="btn buttonns" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">Menu</button>

                <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Owner's Menu</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body sidebar">
                        <div>
                            <div className="d-flex justify-content-between flex-column">
                                <Link className="Links" to='/login-home/add-content'>Success Works</Link><hr />
                                <Link className="Links" to='/login-home/add-help'>Help Section</Link><hr />
                                <Link className="Links" to='/login-home/update'>Notice Board</Link><hr />
                                <Link className="Links" to='/login-home/photo-content'>Update Success Works</Link><hr />
                                <Link className="Links" to='/login-home/worklist'>Update Help Section</Link><hr />
                                <Link className="Links" to='/login-home/notice'>Update Notice Board</Link><hr />
                                <Link className="Links" to='/login-home/change-password'>Change Password</Link><hr />
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn buttonns" type="button" onClick={() => navigate('/login-home')}>Home</button>
                <button className="btn buttonns" type="button" onClick={logout}>Logout</button>
            </div>
            <div className="text-center">
            <SyncLoader loading={loading} />
            </div>
        </div>
    );
}
export default Logout;