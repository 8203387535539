import React, { useState } from "react";
import axios from "axios";
import './css/addcontent.css';
import { useNavigate } from "react-router-dom";
import Logout from "./logout";
import { BaseURL } from "./url";
import SyncLoader from "react-spinners/SyncLoader";

const Addcontent = () => {
    const [details, setdetails] = useState('');
    const [image, setimage] = useState('');
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const submit = () => {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("details", details);
        formdata.append("image", image);
        axios({
            url: `${BaseURL}/api/v1/content/add_Content`,
            method: "POST",
            data: formdata ,
            withCredentials: true,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("user")}`
            }
        }).then((res) => {
            setLoading(false);
            if (res.status===200) {
                alert("Content Posted")
                navigate("/login-home")
            }
        }).catch((err) => {
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "400") {
                alert("Required Fields")
            }else if (code==="401") {
                alert("Unauthorised Request")
            } else {
                alert("Server Error")
            }
        })
    }

    return (
        <div>
            <Logout />
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mb-3 contentbox">
                        <h2 className="text-center">Add Content</h2>
                        <label htmlFor="exampleFormControlTextarea1" className="form-label mb-2">Photo</label>
                        <input type="file" className="form-control mb-2" id="inputGroupFile02" onChange={(e)=>setimage(e.target.files[0])} />
                        <label htmlFor="exampleFormControlTextarea1" className="form-label mb-2">Description</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Describe the Event And all important details" rows="3" onChange={(e) => setdetails(e.target.value)} value={details}></textarea>
                        <div className="d-grid gap-2 col-3 mx-auto">
                            <button className="btn btn-primary mt-3" type="button" onClick={submit}>Submit</button>
                        </div>
                        <p>NOTE: Please Upload the Photograph in resolution of 5000px X 5000px with Aspect Ratio : 1</p>
                    </div>
                </div>
                <div className="text-center mt-5 mb-5" ><SyncLoader loading={loading} /></div>
            </div>
        </div>
    );
}


export default Addcontent;