import React, { useEffect, useState } from "react";
import './css/updatenotice.css';
import { useNavigate } from "react-router-dom";
import Logout from "./logout";
import { BaseURL } from "./url";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";


const Updatenotice = () => {
    const [data, setdata] = useState([]);
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate();
    const getlist = () => {
        axios({
            url: `${BaseURL}/api/v1/update/get_Notice`,
            method: "GET",
            withCredentials: true,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("user")}`
            }
        }).then((res) => {
            if (res.status === 200) {
                setdata(res.data.data)
            }
        }).catch((err) => {
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "400") {
                
            } else {
                
            }
        })
    }
    useEffect(() => {
        getlist();
    }, []);
    const deleteNotice = async (_id) => {
        setLoading(true)
        axios({
            url: `${BaseURL}/api/v1/update/remove_Notice/${_id}`,
            method:"DELETE",
            withCredentials : true,
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            setLoading(false)
            if (res.status===200) {
                alert("Notice Deleted")
                navigate("/login-home")
            }
        }).catch((err)=>{
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "401") {
                alert("Unauthorised Request")
            } else if (code === "400") {
                alert("Required Params")
            } else {
                alert("Internal Server Error") 
            }
        })
    }

    return (
        <div>
            <Logout />
            <h1 className="text-center headlist">Lists</h1>
            <div className="container">
                <div className="text-center mt-5 mb-5">
                <SyncLoader loading={loading} />
                </div>
                <div className="row d-flex justify-content-center noticelist">
                    {
                        data.map((item) =>
                            <div className="col-md-3 ">
                                <div data-bs-spy="scroll" data-bs-target="#simple-list-example" data-bs-offset="0" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0">
                                    <div className="shadow-lg p-3 mb-5 rounded updatenoticebox">
                                        <h6 className="text-start ml-2">{item.update}</h6>
                                        <p className="text-end">Posted On : {item.createdAt.substring(0,10)}</p>
                                        <div className="d-flex justify-content-between">
                                            <button className="btn btn-success" type="button" onClick={(() => navigate(`/login-home/notice/${item._id}`))}>Update</button>
                                            <button className="btn btn-danger" type="button" onClick={(() => deleteNotice(item._id))}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}


export default Updatenotice;