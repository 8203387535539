import React, { useState } from "react";
import './css/addhelp.css';
import { useNavigate } from "react-router-dom";
import Logout from "./logout";
import { BaseURL } from "./url";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";


const Addhelp = () => {
    const [category, setcategory] = useState('');
    const [count, setcount] = useState('');
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate();
    const submit =  () => {
        setLoading(true);
        axios({
            url:`${BaseURL}/api/v1/work/add_Work_Details`,
            method:"POST",
            withCredentials:true,
            data:({category,count}),
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            setLoading(false);
            if (res.status===200) {
                alert("Work Added")
                navigate("/login-home")
            }
        }).catch((err)=>{
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "400") {
                alert("Required Fields")
            } else if (code === 409) {
                alert ("This Section is Already Added")
            } else {
                alert("Server Error") 
            }
        })
    }
    return (
        <div>
            <Logout/>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 mb-3 workbox">
                    <h1 className="text-center pt-2">Works Section</h1>
                        <div className="mb-3">
                            <label htmlFor="formGroupExampleInput" className="form-label">Title</label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Title" onChange={(e) => setcategory(e.target.value)} value={category} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formGroupExampleInput2" className="form-label">Counts</label>
                            <input type="number" className="form-control" id="formGroupExampleInput2" placeholder="Total Counts" onChange={(e) => setcount(e.target.value)} value={count} />
                        </div>
                        <div className="text-center mt-5 mb-3"> <SyncLoader loading={loading} /> </div>
                        <div className="d-flex justify-content-center pb-3">
                            <button className="btn btn-primary mt-3" type="button" onClick={submit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Addhelp;