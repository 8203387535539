import React from "react";
import './css/loginhome.css';
import Logout from "./logout";
const Loginhome = () => {
    return (
        <div>
            <Logout />
             <div className="d-flex justify-content-center">
             <img src={require('./images/logo.png')} className="img-fluid" alt="..."/>
             </div>
        </div>
    );
}
export default Loginhome;