import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import './css/displaywork.css';
import { BaseURL } from "./url";
import axios from "axios";
const Displaywork = () => {
    const [data, setdata] = useState([]);
    const [counter, setcounter] = useState(false);
    useEffect(() => {
        getWork();
    }, []);
    const getWork =  () => {
        axios({
            url:`${BaseURL}/api/v1/work/get_WorkDetails_Home`,
            method:"GET"
        }).then((res)=>{
            if (res.status===200) {
                setdata(res.data.data)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
    function onEnterViewport(){
        setcounter(true)
    }
    function onExitViewport () {
        setcounter(false)
    }
    return (
        <div>
            <div className="container">
                <div className="row d-flex justify-content-around mt-5" style={{marginBottom:"5rem"}}>
                    {
                        data.map((item) =>
                                <div className="col-md-2 infobox d-flex justify-content-around flex-column">
                                    <h5 className="text-center">{item.category}</h5>
                                    <ScrollTrigger onEnter={onEnterViewport} onExit={onExitViewport}>
                                        <p className="text-center">
                                            {counter && <CountUp start={100} end={item.count} duration={2} delay={0} />}+
                                        </p>
                                    </ScrollTrigger>
                                </div>                            
                        )
                    }
                </div>
            </div>
        </div>
    );
}
export default Displaywork;