import React from "react";
import './css/studentawareness.css';
const Studentawareness = () => {
    return (
        <div className="UpperMarginClass">
            <img alt="..." className="img-fluid rounded mb-5" src={require('./images/Student-awareness-1.jpg')}/>
            <div className="d-flex justify-content-center mb-5" ><h1 className="heading">Student Awareness</h1></div>
            <div className="container">
                <div className="row d-flex justify-content-around">
                    <div className="col-md-5 visionrural" data-aos="zoom-in-up">
                        <div className="d-flex justify-content-center mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#6eadff" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                            </svg>
                        </div>
                        <h3 className="text-center">Our Vision</h3>
                        <p>Our vision is a world where every student is equipped with the awareness and knowledge to lead a safe and healthy life. We envision a future where students are well-informed about crucial issues, such as digital safety and personal health, and are empowered to advocate for themselves and others. We strive to build a community where awareness and education go hand in hand, creating a generation of informed, responsible, and compassionate individuals ready to tackle the challenges of tomorrow.</p>
                    </div>
                    <div className="col-md-5 missionrural" data-aos="zoom-in-up">
                        <div className="d-flex justify-content-center mt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#E72929" className="bi bi-rocket-takeoff-fill" viewBox="0 0 16 16">
                                <path d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z" />
                                <path d="M5.205 10.787a7.6 7.6 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925" />
                            </svg>
                        </div>
                        <h3 className="text-center">Our Mission</h3>
                        <p>Our mission is to empower students with the knowledge and skills needed to navigate the challenges of the modern world. We are committed to raising awareness on critical issues such as cybersecurity, mental health, menstrual hygiene, and more. By providing comprehensive education and resources, we aim to create informed and responsible individuals who can make safe, healthy, and ethical decisions. Our goal is to foster a culture of awareness, understanding, and proactive engagement among students.
                        </p>
                    </div>
                </div>
                <div className="row mt-5 mb-5 d-flex justify-content-center">
                    <div className="col-md-8 ruralacheive" data-aos="zoom-in-up">
                        <h3 className="text-center">Duration</h3><hr />
                        <p className="text-center">Our Objective is to achieve our Long-Term goals in comming 2-3 years. And Short-Term goal in 9-11 months.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Studentawareness;