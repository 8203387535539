import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './css/navbar.css';
const Navbar = () => {
    const navigate = useNavigate();
    const [fix, setfix] = useState(false);
    const setfixed = () => {
        if (window.scrollY >= 5) {
            setfix(true);
        } else {
            setfix(false);
        }
    }
    window.addEventListener("scroll", setfixed);
    const report = () => {
        alert("Report Not Genrated Till Now")
    }
    return (
        <div>
            <nav className={fix ? "navbar fixed-top navbar-expand-lg postionnav designchange" : "navbar fixed-top navbar-expand-lg postionnav"}>
                <div className="container-fluid navhead">
                    <Link className="navbar-brand" to="/">
                        <img src={require('./images/headlogo.png')} className="img-fluid newlogo d-flex justify-content-center" alt="..." />
                    </Link>
                    <button type="button" onClick={(()=>navigate('/donate'))} className="donateButton">Donate <svg xmlns="http://www.w3.org/2000/svg" width="10"
                        height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                    </svg></button>
                    <button className="navbar-toggler shadow-none border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className=" sidebar offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 ">
                                <li className="nav-item dropdown">
                                    <Link className="nav-link active dropdown-toggle navlink" style={{ color: 'black' }} to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        About Us
                                    </Link>
                                    <ul className={fix ? "dropdown-menu designchange" : "dropdown-menu "}>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/teams"))} style={{ color: 'black' }}>Our Team</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/vision"))} style={{ color: 'black' }}>Our Vision</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/message"))} style={{ color: 'black' }}>Our Message</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={report} style={{ color: 'black' }}>Annual Report</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/FAQs"))} style={{ color: 'black' }}>FAQs</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link active dropdown-toggle navlink" style={{ color: 'black' }} to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Our Works
                                    </Link>
                                    <ul className={fix ? "dropdown-menu designchange" : "dropdown-menu "}>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/rural"))} style={{ color: 'black' }} s>Rural Education</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/women-empowerment"))} style={{ color: 'black' }}>Women Empowerment</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/divyang"))} style={{ color: 'black' }}>Divyang</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/students-awareness"))} style={{ color: 'black' }}>Students Awareness</Link></li>
                                        <li><Link className="dropdown-item" data-bs-dismiss="offcanvas" onClick={(() => navigate("/needy-people"))} style={{ color: 'black' }}>Charity</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active navlink" data-bs-dismiss="offcanvas" aria-current="page" style={{ color: 'black' }} onClick={(() => navigate("/gallery"))}>Gallery</Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link active navlink" aria-current="page" style={{ color: 'black' }} data-bs-dismiss="offcanvas" onClick={(() => navigate("/updates"))} >Updates</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active navlink" data-bs-dismiss="offcanvas" aria-current="page" style={{ color: 'black' }} onClick={(() => navigate("/contact-us"))}>Contact Us</Link>
                                </li>
                                <li className="nav-item">
                                    <button type="button" data-bs-dismiss="offcanvas" style={{ backgroundColor: "rgb(238, 199, 89)", color: "red", fontWeight: "700" }} className="btn navlink" onClick={(() => navigate('/donate'))}>Donate <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                        height="16" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314" />
                                    </svg></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;