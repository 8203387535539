import React, { useEffect } from "react";
import './css/home.css';
import { Link, useNavigate } from "react-router-dom";
import Carousel from "./carousel";
import Displaywork from "./displaywork";
import Aos from "aos";
import 'aos/dist/aos.css';


const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 900 });
  }, []);
  return (
    <div>
      <Carousel />
      <div className="whatsapp_button"><button onClick={(()=>window.open(`https://wa.me/${8808840403}`,'_blank'))} type="button"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
      </svg> 8808840403</button></div>
      <div className="whoarewe">
        <div className="d-flex justify-content-center" ><h1> Workspace </h1></div>
        <Displaywork />
        <div className="colordiv mt-5">
          <div className="container">
            <div className="row d-flex justify-content-around">
              <div className="col-md-5" data-aos="zoom-in-up">
                <p className="shadow-lg p-3 mb-5 bg-body-tertiary rounded" style={{ textAlign: "justify" }} >Sewa Sath Vikas Foundation, Formed on November 8, 2023, under the Indian Trust Act of 1882, our organization is the brainchild of Aditya Narayan Tiwari, And his Team,whose vision and commitment have been instrumental in its establishment and ongoing success. Aditya Narayan Tiwari’s And His Team journey towards founding Sewa Sath Vikas Foundation began with his involvement in another NGO. He joined with hopes of making a significant impact, but soon realized that the organization lacked the active participation needed to create real change. This experience left Aditya disheartened but also motivated to start something new.</p>
              </div>
              <div className="col-md-5" data-aos="zoom-in-up">
                <img src={require("./images/who_1.jpg")} className="img-fluid shadow-lg p-3 mb-5 bg-body-tertiary rounded" alt="..." />
              </div>
            </div>
            <div className="row d-flex justify-content-around" style={{ marginTop: "5rem" }}>
              <div className="col-md-5" data-aos="zoom-in-up">
                <img src={require("./images/who_2.jpg")} className="img-fluid shadow-lg p-3 mb-5 bg-body-tertiary rounded" alt="..." />
              </div>
              <div className="col-md-5" data-aos="zoom-in-up">
                <p className="shadow-lg p-3 mb-5 bg-body-tertiary rounded" style={{ textAlign: "justify", marginTop: "0.5rem" }}>One day, while at home, Aditya spent time with his cousin Anmol, who is mentally challenged. Anmol had a bright spark in his eyes, Despite his condition,He showed remarkable curiosity and potential. Watching his uncle struggle to provide Anmol with the necessary support and resources deeply moved Aditya. Moved by Anmol’s plight, Aditya sat with him under the shade of a tree and spent hours teaching him to read. <br /><br /> Despite the challenges, Anmol’s enthusiasm and determination were inspiring. Aditya realized that there were many more children like Anmol, whose potential was being hindered by a lack of resources and support. That moment under the tree marked the beginning of a mission that would soon touch countless lives.<br /><br /> This personal experience ignited a spark in him to take matters into his own hands.Determined to create a safe haven for children like Anmol, Aditya gathered a group of passionate individuals who shared his vision. Together, they founded the Sewa Sath Vikas Foundation. Their mission was clear: to provide quality education and comprehensive support to disabled individuals, ensuring they had the opportunity to lead fulfilling lives</p>
              </div>
            </div>
          </div>
        </div>
      </div><hr />
      <div className="program">
        <div className="d-flex justify-content-center"><h1 className="mt-5 mb-5">OUR PROGRAMS</h1></div>
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-md-3 hoverbox">
              <div className="programcontent">
                <h2 className="text-center">Rural Education</h2>
                <div className="d-flex justify-content-center" style={{ margin: "0.9rem" }}>
                  <img src={require('./images/rural-1.jpg')} className="img-fluid rounded" alt="..." />
                </div>
                <p className="p-1">Our Organization helps those students which belong to rural areas. In that areas where there is no scope of Education there  we promote more and more Education . From which each child gets to know the value of Education. And can easily achieve the things which help him to improve thier livelihood.</p>
                <button onClick={(() => navigate('/rural'))} type="button" className="btn btn-warning m-2">Know More</button>
              </div>
            </div>
            <div className="col-md-3 hoverbox">
              <div className="programcontent">
                <h2 className="text-center">Charity</h2>
                <div className="d-flex justify-content-center" style={{ margin: "0.9rem" }}>
                  <img src={require('./images/Charity.jpg')} className="img-fluid rounded" alt="..." />
                </div>
                <p className="p-1">Our NGO organize various charity activities to support underprivileged sections of society, providing essential aid such as food, clothing, and medical assistance to needy families. Our charity initiatives aim to promote equality and community welfare, ensuring that everyone has the opportunity to live a dignified life.</p>
                <button onClick={(() => navigate('/needy-people'))} type="button" className="btn btn-warning m-2">Know More</button>
              </div>
            </div>
            <div className="col-md-3 hoverbox">
              <div className="programcontent">
                <h2 className="text-center">Divyang</h2>
                <div className="d-flex justify-content-center" style={{ margin: "0.9rem" }}>
                  <img src={require('./images/divyang.jpg')} className="img-fluid rounded" alt="..." />
                </div>
                <p className="p-1">Our Organization is dedicated to supporting people with disabilities, whom we respectfully refer to as 'Divyang.' We strive to empower Divyang individuals by providing specialized education, skill development programs, and essential resources. Our initiatives aim to promote inclusivity and independence, ensuring that Divyang persons can lead fulfilling and self-reliant lives.</p>
                <button onClick={(() => navigate('/divyang'))} type="button" className="btn btn-warning m-2">Know More</button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row d-flex justify-content-evenly">
            <div className="col-md-3 hoverbox">
              <div className="programcontent">
                <h2 className="text-center">Students Awareness</h2>
                <div className="d-flex justify-content-center" style={{ margin: "0.9rem" }} >
                  <img src={require('./images/Student-awareness.jpg')} className="img-fluid rounded" alt="..." />
                </div>
                <p className="p-1">Our Organization is committed to raising awareness among students on various important issues. We conduct educational programs and workshops to educate students about personal safety, health, environmental conservation, and social responsibility.By fostering awareness and understanding, we strive to create a generation of responsible and proactive individuals."</p>
                <button onClick={(() => navigate('/students-awareness'))} type="button" className="btn btn-warning m-2">Know More</button>
              </div>
            </div>
            <div className="col-md-3 hoverbox">
              <div className="programcontent">
                <h4 className="text-center mt-1">Women Empowerment</h4>
                <div className="d-flex justify-content-center" style={{ margin: "0.9rem" }}>
                  <img src={require('./images/Women-empowerment.jpg')} className="img-fluid rounded" alt="..." />
                </div>
                <p className="p-1" >Our Organization is deeply committed to women empowerment. We provide education, skill development, and entrepreneurship opportunities to women, enabling them to achieve financial independence and self-confidence. Our initiatives focus on creating a supportive environment where women can thrive and contribute to their communities.</p>
                <button onClick={(() => navigate('/women-empowerment'))} type="button" className="btn btn-warning m-2">Know More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="d-flex justify-content-center">
          <h1 className="collab mb-5">COLLABORATIONS </h1>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-2 col-md-6 d-flex justify-content-center">
            <Link >
              <img src={require('./images/collcom.png')} onClick={(() => window.open('https://collcom.org/'))} className="img-fluid rounded" alt="..." /></Link>
          </div>
        </div>
      </div>
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={require('./images/111.jpg')} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('./images/222.jpg')} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('./images/333.jpg')} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );

}
export default Home;