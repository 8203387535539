import React from "react";
import './css/team.css';
const Team = () => {
    return (
        <div className="divteam">
            <div className="UpperMarginClass">
                <img src={require('./images/Teamwork.jpg')} className="img-fluid rounded" alt="..."/>
            </div>
            <div className="container-fluid teambox">
                <div className="row mt-2 d-flex justify-content-around">
                    <h2 className="text-center mt-5 mb-5">Our Team</h2>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Founder.jpeg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Founder/Trustee</h3>
                            <p className="text-center">Aditya Narayan Tiwari</p>
                        </div>
                    </div>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Secretary.jpeg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Secretary</h3>
                            <p className="text-center">Ashutosh Kumar Singh</p>
                        </div>
                    </div>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Vice President.jpeg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Vice President</h3>
                            <p className="text-center">Priyanshu Gupta</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 d-flex justify-content-around">
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Deputy Secretary.jpg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Joint Secretary</h3>
                            <p className="text-center">Mihir singh</p>
                        </div>
                    </div>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/treasure.jpg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Treasurer</h3>
                            <p className="text-center">Prasoon Gupta</p>
                        </div>
                    </div>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Member Ishal singh.jpeg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Member</h3>
                            <p className="text-center">Ishal singh</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 d-flex justify-content-around">
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Member Shreshthta Tiwari.jpeg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Member</h3>
                            <p className="text-center">Shreshtha tiwari</p>
                        </div>
                    </div>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="text-center">
                            <img src={require('./images/Member Nishtha Tiwari.jpg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Member</h3>
                            <p className="text-center">Nishtha tiwari</p>
                        </div>
                    </div>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/member Utkarsh.jpg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Member</h3>
                            <p className="text-center">Utkarsh Gupta</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 d-flex justify-content-evenly">
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Member sarvesh.jpg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Member</h3>
                            <p className="text-center">Sarvesh Gupta</p>
                        </div>
                    </div>
                    <div className="col-md-3 pt-3 shadow-lg p-3 mb-5 design rounded">
                        <div className="">
                            <img src={require('./images/Member Priyanshu Singh.jpg')} className="img-fluid founder" alt="..." />
                        </div>
                        <div className="profile">
                            <h3 className="text-center">Member</h3>
                            <p className="text-center">Priyanshu Singh</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Team;