import React, { useEffect, useState } from "react";
import './css/update.css';
import { BaseURL } from "./url";
import axios from "axios";
const Update = () => {
    const [data, setdata] = useState([]);

    const getnotice = async () => {
        axios({
            url: `${BaseURL}/api/v1/update/getNoticeUpdates`,
            method: "GET"
        }).then((res) => {
            if (res.status === 200) {
                setdata(res.data.data)
            }
        }).catch((err) => {
            alert('Error');
        })
    }
    useEffect(() => {
        getnotice();
    }, []);
    return (
        <div style={{ minHeight: "90vw" }}>
            <h1 className="text-center noticeboardname" style={{ marginTop: 110 }}>Notice Board</h1>
            <div className="container noticecontent">
                {
                    data.length ?
                        <div className="row d-flex justify-content-center mt-5">
                            {
                                data.map((item) =>
                                    <div className="col-md-3 d-flex justify-content-between flex-column cluster">
                                        <div data-bs-spy="scroll" data-bs-target="#simple-list-example" data-bs-offset="0" data-bs-smooth-scroll="true" className="scrollspy-example" tabindex="0">
                                            <div className="shadow-lg p-3 mb-5 rounded noticebox">
                                                <h5 className="text-start">{item.update}</h5>
                                                <p className="text-end">Posted On : {item.createdAt.substring(0, 10)}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        :
                        <div className="row d-flex justify-content-center mt-5">
                            <div className="col-md-3 d-flex justify-content-between flex-column cluster">
                                <div data-bs-spy="scroll" data-bs-target="#simple-list-example" data-bs-offset="0" data-bs-smooth-scroll="true" className="scrollspy-example" tabindex="0">
                                    <div className="shadow-lg p-3 mb-5 rounded noticebox">
                                        <h5 className="text-start">NO NOTICES OR ANY UPDATES</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>
    );
}
export default Update;