import React from "react";
import './css/message.css';
const Message = () => {
    return (
        <div className="UpperMarginClass">
             <img src={require('./images/Message.jpg')} width={"100%"} className="img-fluid " alt="..."/>
            <div className="container mb-5 mt-5" >
                <div className="row message d-flex justify-content-between">
                    <div className="col-md-5 ">
                       <p className="shadow-lg p-3 mb-5 bg-body-tertiary rounded">I am very happy to introduce “SEWA SATH VIKAS FOUNDATION” Non-profit based Organization. On behalf of SSV Foundation, I welcome you all.With such aims and objectives, it was established having strong message for the various purpose, to do something for the growth of our Community in various segments for the development of India SSV Foundation with a motive for the development of poor children’s, Disabled, Old age people, Women’s etc. in the segment of Education, Health, Agriculture, Sustainable Development, etc.As the Founder of SSV Foundation, I strongly believe that it’s our duty and responsibility to give back to the society in which we live, in return of the many things we avail from it in our day-to day lives. SSV Foundation as an organization stems from this root belief.We are very grateful for the support that we get from many individual people that make our work possible.We always welcome support offered in any way, if you are youths, please feel free to visit us and join our activities at any time.<br/>
                       <b>Best Wishes & Regards<br/>
                       ADITYA NARAYAN TIWARI<br/>
                       President<br/>
                       (Founder & Chairman)<br/>
                       Jai Hind!</b></p>
                    </div>
                    <div className="col-md-5">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/-tTNuFpwIP8?si=AkMeeP-L2Ws0id2p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/iAIk8La_QIs?si=Va3FAqLRKv3cniDC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>                    
                </div>
            </div>

        </div>
    );
}
export default Message;