import React, { useEffect, useState } from "react";
import './css/carousel.css';
import { BaseURL } from "./url";
import axios from "axios";
const Carousel = () => {
    const [content, setcontent] = useState([]);
    const getContent =  () => {
        axios({
            url:`${BaseURL}/api/v1/content/get_Content_Home`,
            method:"GET"
        }).then((res)=>{
            if (res.status===200) {
                setcontent(res.data.data)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
    useEffect(() => {
        getContent();
    }, []);
    return (
        <div>
            <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner carTop">
                    {
                        content.map((item,index) =>
                            <div className={index===0 ? "carousel-item active":"carousel-item "} data-bs-interval="2000">
                                <img src={item.image} className="d-block w-100" alt="..." />
                            </div>
                        )
                    }
                </div>
                <button style={{backgroundColor:"black",width:"fit-content",height:"fit-content",alignSelf:"center",padding:"1rem"}} className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button style={{backgroundColor:"black",width:"fit-content",height:"fit-content",alignSelf:"center",padding:"1rem"}} className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
}
export default Carousel;