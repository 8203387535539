import React, { useEffect, useState } from "react";
import './css/updatehelp.css';
import { useNavigate } from "react-router-dom";
import Logout from "./logout";
import { BaseURL } from "./url";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";


const Updatehelp = () => {
    const [data, setdata] = useState([]);
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate();
    const worklist = async () => {
        axios({
            url: `${BaseURL}/api/v1/work/get_Details_Work`,
            method: "GET",
            withCredentials: true,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("user")}`
            }
        }).then((res) => {
            if (res.status === 200) {
                setdata(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        worklist();
    }, []);
    const deleteWork = (_id) => {
        setLoading(true)
        axios({
            url: `${BaseURL}/api/v1/work/delete_Work_Details`,
            method: "DELETE",
            withCredentials: true,
            data: ({ _id }),
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("user")}`
            }
        }).then((res) => {
            setLoading(false)
            if (res.status === 200) {
                alert("Work Deleted")
                navigate("/login-home")
            }
        }).catch((err)=>{
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "400") {
                alert("Data Missing")
            } else if (code === "401") {
                alert("Unauthorised Request")
            } else {
                alert("Server Error")     
            }
        })
    }
    return (
        <div>
            <Logout />
            <h1 className="text-center">Total Works List</h1>
            <div className="container">
            <div className="text-center mt-3 mb-3"> <SyncLoader loading={loading} /> </div>
                <div className="row d-flex justify-content-center">
                    {
                        data.map((item) =>
                            <div className="col-md-3 ">
                                <div className="shadow-lg p-3 mb-5 rounded infoboxlist">
                                    <h5 className="text-center">{item.category}</h5>
                                    <p className="text-center">{item.count}</p>
                                    <div className="d-flex justify-content-between">
                                        <button className="btn btn-success" type="button" onClick={(() => navigate(`/login-home/worklist/${item._id}`))} >Update</button>
                                        <button className="btn btn-danger" type="button" onClick={(() => deleteWork(item._id))}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
export default Updatehelp;