import React from "react";
import './css/gallery.css';
import { Link } from "react-router-dom";
const Gallery = () => {
    return (
        <div>
            <div className="container-fluid" style={{ marginTop: 105 }}>
                <div className="row frame">
                    <h1 className="text-center" >Unite For Nature</h1>
                    <div className="col d-flex justify-content-center total ">
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/swacch bharat (1).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/swacch bharat (2).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/swacch bharat (3).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/swacch bharat (4).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/swacch bharat (5).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/swacch bharat (6).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                    </div>
                </div>
                <div className="row frame">
                    <h1 className="text-center">Rural Education</h1>
                    <div className="col d-flex justify-content-center total">
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Rural Education (1).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Rural Education (2).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Rural Education (3).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Rural Education (4).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                            {/* <p>Awareness reagarding Humanity</p> */}
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Rural Education (5).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Rural Education (6).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Rural Education (7).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                    </div>
                </div>
                <div className="row frame">
                    <h1 className="text-center">Feeding The Needy</h1>
                    <div className="col d-flex justify-content-center total">
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Feeding (1).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Feeding (2).jpeg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                    </div>
                </div>
                <div className="row frame">
                    <h1 className="text-center">Computer Education in Rural Areas</h1>
                    <div className="col d-flex justify-content-center total">
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (1).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (2).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (3).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (4).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (5).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (6).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (7).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (8).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (9).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (10).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                        <div className="phototext d-flex justify-content-center">
                            <img src={require('./images/Computer Education in Rural Areas (11).jpg')} className="img-fluid rounded mx-auto d-block" alt="..." />
                        </div>
                    </div>
                </div>
                <div className="row frame">
                    <h1 className="text-center">Works</h1>
                    <div className="col d-flex justify-content-center total">                        
                        <div className="phototext d-flex justify-content-center flex-column pdfview">
                            <Link className="view_link" onClick={(() => window.open(`${require('./images/work_1.pdf')}`, '_blank'))}><img src={require('./images/work_1.png')} alt="..." className="img-fluid" />
                            Read Full Article</Link>
                        </div>
                        <div className="phototext d-flex justify-content-center flex-column pdfview">
                            <Link className="view_link" onClick={(() => window.open(`${require('./images/work_2.pdf')}`, '_blank'))}><img src={require('./images/work_2.png')} alt="..." className="img-fluid" />
                            Read Full Article</Link>
                        </div>
                        <div className="phototext d-flex justify-content-center flex-column pdfview">
                            <Link className="view_link" onClick={(() => window.open(`${require('./images/work_3.pdf')}`, '_blank'))}><img src={require('./images/work_3.png')} alt="..." className="img-fluid" />
                            Read Full Article</Link>
                        </div>
                        <div className="phototext d-flex justify-content-center flex-column pdfview">
                            <Link className="view_link" onClick={(() => window.open(`${require('./images/work_4.pdf')}`, '_blank'))}><img src={require('./images/work_4.png')} alt="..." className="img-fluid" />
                            Read Full Article</Link>
                        </div>
                        <div className="phototext d-flex justify-content-center flex-column pdfview">
                            <Link className="view_link" onClick={(() => window.open(`${require('./images/work_5.pdf')}`, '_blank'))}><img src={require('./images/work_5.png')} alt="..." className="img-fluid" />
                            Read Full Article</Link>
                        </div>
                        <div className="phototext d-flex justify-content-center flex-column pdfview">
                            <Link className="view_link" onClick={(() => window.open(`${require('./images/work_6.pdf')}`, '_blank'))}><img src={require('./images/work_6.png')} alt="..." className="img-fluid" />
                            Read Full Article</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Gallery;