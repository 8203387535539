import React from "react";
import './css/vision.css';
const Vision = () => {
    return (
        <div className="UpperMarginClass">
            <img src={require('./images/Vision.jpg')} className="img-fluid" alt="..." />
            <div className="container mt-5">
                <div className="row d-flex justify-content-around vision">
                    <div className="col-md-5 shadow-lg p-3 mb-5 bg-body-tertiary rounded visioncol">
                        <h1 className="text-center">Vision</h1>
                        <p>At Sewa Sath Vikas Foundation, we dream of a world where everyone, no matter their abilities or background, has access to Good education, Healthcare, and opportunities to Grow. We want to help people with Disabilities and those in Rural areas live better lives by providing them with the support and resources they need.Our goal is to connect urban and rural communities through innovative programs like our Magic Box computer education, which helps children learn essential skills. We also focus on giving people with disabilities the care and attention they deserve, promoting a culture of acceptance and equality.By working together with partners, engaging with the community, and advocating for change, we aim to uplift those who are often overlooked. We believe in a sustainable approach to development that prioritizes health, education, and overall well-being. Our vision is a fair and inclusive society where everyone has the chance to succeed and build a brighter future.</p>
                    </div>
                    <div className="col-md-5 shadow-lg p-3 mb-5 bg-body-tertiary rounded">
                        <h1 className="text-center">Mission</h1>
                        <p>At SEWA SATH VIKAS FOUNDATION, We are dedicated to transforming lives through education and inclusion. Our mission is to empower children and individuals with disabilities by providing accessible and quality education, raising awareness, and fostering a supportive community.We believe that every child, regardless of their abilities, deserves the opportunity to learn, grow, and succeed. Through our programs, we aim to break down the barriers that prevent access to education for children with disabilities, ensuring they have the tools and resources they need to reach their full potential.Our organization is committed to advocating for the rights and dignity of individuals with disabilities, promoting inclusion and understanding within society. We work tirelessly to create an environment where every person is valued and respected, Where diversity is celebrated, and where everyone can contribute to the community.By engaging with local communities, educators, and policymakers, we strive to create sustainable change.</p>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Vision;