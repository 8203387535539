import React, { useEffect, useState } from "react";
import './css/noticeupdated.css';
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "./url";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";


const Noticeupdated = () => {
    const [update,setupdate]=useState('');
    const [loading,setLoading] = useState(false)
    const params = useParams();
    const navigate = useNavigate();
    const _id = params._id
    const getDetail = async () => {
       axios({
           url : `${BaseURL}/api/v1/update/get_update_Id/${_id}`,
           method : "GET",
           withCredentials :true,
           headers:{
            "Authorization":`Bearer ${localStorage.getItem("user")}`
           }
       }).then((res)=>{
        if (res.status === 200) {
            setupdate(res.data.data.update)
        }
       }).catch((err)=>{

       })
    }
    useEffect(() => {
        getDetail();
        // eslint-disable-next-line
    },[]);
    const updateNotice=async()=>{
        setLoading(true)
        axios({
            url:`${BaseURL}/api/v1/update/update_Notice`,
            method : "PUT",
            withCredentials :true,
            data :({update,_id}),
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            setLoading(false)
            if (res.status===200) {
                alert("Notice Updated")
                navigate("/login-home")
            }
        }).catch((err)=>{
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "401") {
                alert("Unauthorised Request")
            } else if (code === "400") {
                alert("Required Params")
            } else {
                alert("Internal Server Error") 
            }
        })
    }
    return (
        <div>
            <h1 className="text-center" style={{ marginTop: 110 }}>Notice Updated</h1>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-4 mb-3">
                        <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label mb-2">Notice Update</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Update News Here" rows="5" onChange={(e)=>setupdate(e.target.value)} value={update}></textarea>
                        </div>
                        <div className="text-center mt-3 mb-3">
                            <SyncLoader loading={loading} />
                        </div>
                        <div className="d-grid gap-2 col-3 mx-auto">
                            <button className="btn btn-primary mt-3" type="button" onClick={updateNotice}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Noticeupdated;