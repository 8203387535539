import React, { useState } from "react";
import './css/login.css';
import { useNavigate } from "react-router-dom";
import { BaseURL } from "./url";
import axios from "axios";
import Cookies from "js-cookie";
import SyncLoader from "react-spinners/SyncLoader";

const Login = () => {
    const [userid, setuserid] = useState('');
    const [password, setpassword] = useState('');
    const [check, setcheck] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const login = async () => {
        setLoading(true);
        axios({
            method: "POST",
            url: `${BaseURL}/api/v1/user/login`,
            data: ({ userid, password })
        }).then((res) => {
            if (res.data.statusCode === 200) {
                localStorage.setItem("user", res.data.data.refreshToken)
                Cookies.set('user', res.data.data.refreshToken, { expires: 2, path: '' })
                setLoading(false);
                navigate('/login-home')
            }
        }).catch((err) => {
            setLoading(false);
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code === "400") {
                alert("Required Fields")
            } else if (code === '401' || code === "404") {
                alert("Invalid Credentials")
            } else {
                alert("Server Error")
            }
        })
    }
    return (
        <div>
            <h1 className="text-center mb-5" style={{ marginTop: 110 }}>Owner Login</h1>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-4 mb-5">
                        <input type="text" className="form-control" placeholder="User Id" aria-label="First name" onChange={(e) => setuserid(e.target.value)} value={userid} />
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-4 mb-5">
                        <input type={check ? "text" : "password"} className="form-control" placeholder="Password" aria-label="First name" onChange={(e) => setpassword(e.target.value)} value={password} />
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-4 mb-5">
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value={check} onChange={(e) => setcheck(e.target.checked)} />
                        <label className="form-check-label" style={{marginLeft:"5px"}} htmlFor="inlineCheckbox1"> Show Password</label>
                    </div>
                    <div className="text-center mb-5"><SyncLoader loading={loading}/></div>
                </div>
                <div className="d-grid gap-2 col-1 mb-3 mx-auto">
                    <button className="btn btn-primary" type="button" onClick={login}>Login</button>
                </div>
            </div>
        </div>
    );
}
export default Login;