import React, { useEffect, useState } from "react";
import './css/workupdated.css';
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "./url";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";


const Workupdated = () => {
    const [category, setcategory] = useState('');
    const [count, setcount] = useState('');
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate();
    const params = useParams();
    const  _id  = params._id;
    const getdetails = () => {

        axios({
            url: `${BaseURL}/api/v1/work/get_Details/${_id}`,
            method :"GET",
            withCredentials:true,
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            if (res.status===200) {
                setcategory(res.data.data.category)
                setcount(res.data.data.count)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
    useEffect(() => {
        getdetails();
        // eslint-disable-next-line
    }, []);
    const submit = async () => {
        setLoading(true)
        axios({
            url:`${BaseURL}/api/v1/work/update_Work_Details`,
            method:"PUT",
            withCredentials:true,
            data:({category,count,_id}),
            headers:{
                "Authorization":`Bearer ${localStorage.getItem("user")}`
            }
        }).then((res)=>{
            setLoading(false)
            if (res.status===200) {
                alert("Content Updated")
                navigate("/login-home")
            }
        }).catch((err)=>{
            setLoading(false)
            const message = err.message;
            const code = message.substring(message.length - 3).trim();
            if (code==="400") {
                alert("Reuired Fields")
            } else if (code==="401") {
                alert("Unauthorised Request")
            } else {
                alert("Internal Server Error")
            }
        })
    }
    return (
        <div>
            <h1 className="text-center" style={{ marginTop: 110 }}>Work Details</h1>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-4 mb-3">
                        <div className="mb-3">
                            <label htmlFor="formGroupExampleInput" className="form-label">Title</label>
                            <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Title" onChange={(e) => setcategory(e.target.value)} value={category} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formGroupExampleInput2" className="form-label">Counts</label>
                            <input type="number" className="form-control" id="formGroupExampleInput2" placeholder="Total Counts" onChange={(e) => setcount(e.target.value)} value={count} />
                        </div>
                        <div className="text-center mt-3 mb-3" > <SyncLoader loading={loading} /> </div>
                        <div className="d-grid gap-2 col-3 mx-auto">
                            <button className="btn btn-primary mt-3" type="button" onClick={submit}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Workupdated;