import React from "react";
import './css/donate.css';
const Donate = () => {
    return (
        <div style={{ marginTop: 110 }}>
            <img src={require('./images/donate.png')} className="img-fluid rounded" alt="..." />
            <div className="d-flex justify-content-center"><h3 className="text-center heading mt-5" >BE THE LIGHT IN SOMEONE'S DARKNESS</h3></div>
            <div className="container">
                <div className="row d-flex justify-content-around mt-5 mb-5" style={{alignItems:"center"}}>
                    <div className="col-md-5">
                        <p className="shadow-lg p-3 mb-5 bg-body-tertiary rounded fw-bold" style={{ textAlign: "justify" }} >At SEWA SATH VIKAS FOUNDATION, We see the faces of hope, struggle, and determination every day. We are committed to changing lives, but we can't do it alone. Your kindness and generosity can light up the path for those who need it most.Imagine a child in a rural village, eager to learn but lacking resources. Your donation can fill their hands with books and their hearts with dreams.Picture a person with disabilities, yearning for acceptance and opportunities. Your support can break down barriers and build bridges to a brighter future.Think of a community struggling to thrive, waiting for a helping hand. Your contribution can ignite the spark of change, bringing hope and joy to countless lives.Every donation, no matter how small, is a beacon of hope. It tells someone, "You are not alone. We care about you."Join us in this journey of compassion and impact. Your support is the heartbeat of our mission, and with your help, we can create a world where every life is valued and every dream is possible.Thank you for opening your heart and making a difference.</p>
                    </div>
                    <div className="col-md-5">
                        <p className="shadow-lg p-3 mb-5 bg-body-tertiary rounded" >
                            <b>Account Number</b> :- 700801010050176 <br/>
                           <b> Account Holder Name </b> :- SEWA SATH V VIKAS FOUNDATION<br/>
                           <b> IFSC CODE </b> :- UBIN0570087 <br/>
                           <b> Bank Name </b> :- Union Bank Of India <br/>
                           <b> Mobile Number </b> :- 8808840403 <br/>
                           <b> Branch Name</b> :- VILLAGE HUSENPUR, CHOCHAKPUR MARG 233001  <br/>
                           <b> Branch Code</b> :- 70080 <br/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Donate;