import React, { useState } from "react";
import './css/changepassword.css';
import { useNavigate } from "react-router-dom";
import Logout from "./logout";
import { BaseURL } from "./url";
import axios from "axios";
import Cookies from "js-cookie";
import SyncLoader from "react-spinners/SyncLoader";


const Changepassword = () => {
    const [userid, setuserid] = useState('');
    const [oldPassword, setpassword] = useState('');
    const [newPassword, setnewpassword] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('');
    const [checkbox, setcheckbox] = useState(false);
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const change = () => {
        setLoading(true);
        if (newPassword === confirmpassword) {
            axios({
                url: `${BaseURL}/api/v1/user/change_Password`,
                method: "PUT",
                withCredentials: true,
                data: ({ userid, oldPassword, newPassword }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("user")}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    localStorage.clear();
                    Cookies.remove("user");
                    setLoading(false)
                    alert("Password Changed Successfully")
                    navigate("/login")
                }
            }).catch((err) => {
                setLoading(false)
                const message = err.message;
                const code = message.substring(message.length - 3).trim();
                if (code==='400') {
                    alert("Required Fields")
                } else if (code===401) {
                    alert("Unauthorised Request")
                } else if (code === '409') {
                    alert("Invalid Credentials")
                } else {
                    alert("Internal Server Error")
                }
            })
        } else {
            setLoading(false)
            alert("New Password Not Match")
        }
    }
    return (
        <div>
            <Logout />
            <h3 className="text-center">Change Password</h3>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-4">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={(e) => setuserid(e.target.value)} value={userid} />
                            <label htmlFor="floatingInput">User Id</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type={checkbox ? "text" : "password"} className="form-control" id="floatingPassword1" placeholder="Password" onChange={(e) => setpassword(e.target.value)} value={oldPassword} />
                            <label htmlFor="floatingPassword"> Old Password</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type={checkbox ? "text" : "password"} className="form-control" id="floatingPassword2" placeholder="Password" onChange={(e) => setnewpassword(e.target.value)} value={newPassword} />
                            <label htmlFor="floatingPassword"> New Password</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type={checkbox ? "text" : "password"} className="form-control" id="floatingPassword3" placeholder="Password" onChange={(e) => setconfirmpassword(e.target.value)} value={confirmpassword} />
                            <label htmlFor="floatingPassword">Confirm Password</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={checkbox} id="defaultCheck1" onChange={(e) => setcheckbox(e.target.checked)} />
                            <label className="form-check-label" htmlFor="defaultCheck1">Show Password</label>
                        </div>
                        <div className="text-center mt-3 mb-3">
                            <SyncLoader loading={loading} />
                        </div>
                        <div className="d-flex justify-content-center mb-5 mt-4">
                            <button className="btn btn-primary" type="button" onClick={change}>Change Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Changepassword;